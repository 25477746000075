import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Black Box SMS Integrations",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-07T17:09:30.000Z",
  "url": "/2019/05/black-box-sms-integrations/",
  "categories": ["My Projects"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` `}<strong parentName="p">{`Tech Stack:`}</strong>{` PHP, Laravel, Mockery, Guzzle, mongo, docker `}</p>
    <h2>{`Description I got this project that had an implementation with a specific SMS provider. The code that used the SMS service was tightly coupled to the specific integration. That meant adding a new integration would be very hard and should make more providers added easily.`}</h2>
    <h4>{`The Workflow:`}</h4>
    <ol>
      <li parentName="ol">{`Decide on the contracts your code will have`}</li>
      <li parentName="ol">{`Change the usage of the old code with the new contracts`}</li>
      <li parentName="ol">{`Create the new provider library code`}</li>
      <li parentName="ol">{`Update the existing provider code`}</li>
      <li parentName="ol">{`create the adapter for each of them so they will fit our contract`}</li>
      <li parentName="ol">{`create a factory for getting an implementation for SMS provider`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      